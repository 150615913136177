import React, { useEffect, useRef, useState } from "react";
import { Box, Typography, Container, Stack } from "@mui/material";
import PrimaryButton from "../../../components/Custom/PrimaryButton/PrimaryButton";
import HowWorksSection from "./HowWorksSection";
import HomeProducts from "./HomeProducts/HomeProduct";
// import OurPartners from './OurPartners';
import AnalysisForm from "./AnalysisForm";
import ReviewSection from "./ReviewSection/ReviewSection";
import { useLocation, useNavigate } from "react-router";
import HomeLeadForm from "../../../components/HomeLeadForm/HomeLeadForm";
import { styled } from "@mui/material";
import Categories from "./Categories/Categories";
import FourBlog from "../../NewBlogs/BlogComponents/FourBlog";
import BannerCarousel from "./BannerCarousel/BannerCarousel";
import BecomeAPartner from "./BecomeAPartner/BecomeAPartner";
import MainHeader from "../../../components/MainHeader/MainHeader";
import Brands from "../../../components/Brands/Brands";
import YellowButton from "../../../components/YellowButton/YellowButton";
import { BookWrapper } from "../../../portfolio/userPortfolioProfile.style";
import BookSiteVisit from "../../../portfolio/Projects/BookNow/BookSiteVisit";
import ProjectsSlider from "./ProjectsSection/ProjectsSlider";
import CO2Banner from "../../../assets/banners/BannerCO2Long.png";
import EcofyFinancingBanner from "../../../assets/banners/EcofyFinacingBannerWithButton.png";
import FAQ from "../../../components/FAQ/FAQ";
import SolarLeadForm from "../../SolarLeadForm/SolarLeadForm";
import { createSearchParams } from "react-router-dom";
import {
  TabBtn,
  TabBtnHome,
} from "../../../components/SolarCalculator/SolarCalculator.style";
import SolarLeadForm2 from "../../SolarLeadForm/SolarLeadForm2";
import SolarCalculator from "../../../components/SolarCalculator/SolarCalculator";
import SolarLeadForm3 from "../../SolarLeadForm/SolarLeadForm3";
import QuoteFormHome from "../../../components/QuoteFormHome/QuoteFormHome";
import ServiceSectionHomePage from "../../../components/ServiceHomePage/ServiceSectionHomePage";

export const HereOuterBox = styled(Box)(() => ({
  backgroundImage: `linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5)), url("https://solruf-node-static-files.s3.ap-south-1.amazonaws.com/N+Hero+image+(1)+(1)+(1).png")`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "bottom",
}));

const boxStyle = {
  borderBottom: "none",
  backgroundSize: "cover",
  backgroundPosition: "center",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: 3,
};

const NewHomePage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [leadFormOpen, setLeadFormOpen] = useState(false);
  const [leadFormPageIndex, setLeadFormPageIndex] = useState(1);
  const leadFormRef = useRef(null);

  const handleCalcNav = () => {
    navigate("#solar-calc");
  };

  // const handleLeadNav = () => {
  //   navigate("#solar-lead");
  // };

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location, handleCalcNav]);

  useEffect(() => {
    let timer;
    const storageData = JSON.parse(
      localStorage.getItem("leadFormWithVisitShown")
    );
    if (storageData) {
      timer = setTimeout(() => {
        setLeadFormOpen(true);
      }, 7200000);
      return;
    } else {
      localStorage.setItem("leadFormWithVisitShown", true);
      timer = setTimeout(() => {
        setLeadFormOpen(true);
      }, 2000);
    }

    return () => clearTimeout(timer);
  }, []);

  const scrollToLeadForm = () => {
    leadFormRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Box id="home">
      <HereOuterBox>
        <MainHeader homePage />
        <Container sx={{ pt: 5 }}>
          <Box sx={{ ...boxStyle, height: [500, 800] }}>
            <Typography variant="h4" color={"#ffffff"}>
              Welcome to
            </Typography>
            <Box
              sx={{
                "& img": {
                  width: {
                    xs: 300,
                    sm: 400,
                    md: 500,
                    lg: 600,
                  },
                },
              }}
            >
              <img src="https://i.ibb.co/CzrKjfq/Logo-White.png" alt="logo" />
            </Box>
            {/* <Typography variant="h5" color={"#ffffff"}>
              India’s No. 1 Solar Solutions !
            </Typography> */}
            <Typography variant="h5" color={"#ffffff"}>
              We are the Middleware between Solar Developers and Clients
            </Typography>
            <Stack direction="row" gap={3}>
              {/* <PrimaryButton onClick={() => navigate("/solar-enquiry-form")}>
                Solar Enquiry
              </PrimaryButton> */}
              {/* <PrimaryButton onClick={() => navigate("/products")}>
                Explore More!
              </PrimaryButton> */}
            </Stack>
          </Box>
        </Container>
      </HereOuterBox>
      {/* <HowWorksSection /> */}

      {/* <WorksInAction /> */}

      <Container>
        {/* edited form new top */}

        <ServiceSectionHomePage />

        <div ref={leadFormRef}>
          <QuoteFormHome />
        </div>

        {/* <SolarLeadForm2 /> */}
        {/* <div ref={leadFormRef}>
          <SolarLeadForm3
            leadFormPageIndex={leadFormPageIndex}
            setLeadFormPageIndex={setLeadFormPageIndex}
          />
        </div> */}

        <SolarCalculator
          setLeadFormPageIndex={setLeadFormPageIndex}
          scrollToLeadForm={scrollToLeadForm}
        />

        {/* <HomeProducts />
        <Categories /> */}

        <BannerCarousel />
        <ProjectsSlider />
        <BookSiteVisit
          onClick={(e) => {
            e.preventDefault();
            navigate("/#quote-form");
            // navigate("/solar-enquiry-form?service=solar_installation");
            // navigate({
            //   pathname: "/#quote-form",
            //   search: createSearchParams({
            //     service: "solar_installation",
            //   }).toString(),
            // });
          }}
        />
        {/* <Box sx={{ display: ["none", "block"] }}>
          <FourBlog home={true} />
        </Box> */}

        {/* <NewCalculator /> */}
        {/* <OurPartners /> */}
        <Brands />
      </Container>
      <Box
        sx={{
          mx: { xs: 4, sm: 2 },
          borderRadius: 4,
          position: "relative",
          overflow: "hidden",
          cursor: "pointer",
        }}
        onClick={() =>
          // navigate(
          //   "/blogs/making-solar-energy-accessible-with-solruf-and-ecofy"
          // )
          window.open(
            "https://medium.com/@socialmedia_90251/making-solar-energy-accessible-with-solruf-and-ecofy-33c14915778b",
            "_blank"
          )
        }
      >
        <img
          src={EcofyFinancingBanner}
          alt="ecofy-financing-banner"
          style={{
            height: "auto",
            maxHeight: "100%",
            width: "100%",
            borderRadius: "inherit",
          }}
        />
      </Box>
      <Box sx={{ ml: [0, 0, 2] }}>
        <ReviewSection />
      </Box>

      <Box
        sx={{
          mx: { xs: 4, sm: 2 },
          borderRadius: 4,
          position: "relative",
          overflow: "hidden",
        }}
      >
        <img
          src={CO2Banner}
          alt="Carbon-footprint"
          style={{
            height: "auto",
            maxHeight: "100%",
            width: "100%",
            borderRadius: "inherit",
          }}
        />
      </Box>

      {/* <BecomeAPartner /> */}
      {/* <Box
        sx={{
          my: 4,
          mx: { xs: 4, sm: 2 },
          borderRadius: 4,
          position: "relative",
          overflow: "hidden",
        }}
      >
        <TabBtnHome
          sx={{
            bgcolor: "primary.dark",
          }}
          onClick={handleCalcNav}
        >
          <Typography
            variant="h5"
            sx={{
              color: "primary.main",
              fontWeight: "700",
              textAlign: "center",
              "@media (max-width: 600px)": {
                fontSize: "0.8rem",
              },
            }}
          >
            SOLAR ROOFTOP CALCULATOR
          </Typography>
          <Typography
            variant="p"
            sx={{
              textAlign: "center",
              fontSize: "1rem",
              color: "white",
              "@media (max-width: 600px)": {
                display: "none",
              },
            }}
          >
            Check your Solar installation requirements & get NIL
            electricity bills.
          </Typography>
        </TabBtnHome>
      </Box> */}
      <BookWrapper
        sx={{
          my: 4,
          mx: { xs: 4, sm: 2 },
          p: [1.5, 8],
          borderRadius: 4,
        }}
      >
        <Box
          sx={{
            position: "absolute",
            width: 1,
            height: 1,
            bgcolor: "#000",
            opacity: 0.6,
            top: 0,
            left: 0,
            borderRadius: 4,
          }}
        ></Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: ["column", "row"],
            alignItems: "center",
            justifyContent: { xs: "center", sm: "space-around" },
            width: "100%",
            height: "100%",
          }}
        >
          <Typography
            //  variant='h6'
            sx={{
              fontWeight: 700,
              mb: [2, 0],
              color: "#fff",
              zIndex: 1,
              fontSize: ["1rem", "2rem"],
            }}
          >
            Join our solar EPC Network!
          </Typography>
          <Box sx={{ zIndex: "100" }}>
            <PrimaryButton
              sx={{
                display: { xs: "none", sm: "block" },
                borderRadius: "36px",
                backgroundColor: "#FFD05B",
                padding: "0.8rem 1.8rem",
                color: "black",
                fontWeight: "600",
                fontSize: "1rem",
              }}
              onClick={(e) => {
                e.preventDefault();

                navigate("/#quote-form");
              }}
            >
              Join Now
            </PrimaryButton>
            <Box sx={{ display: { xs: "block", sm: "none" } }}>
              <YellowButton
                onClick={(e) => {
                  e.preventDefault();

                  navigate("/#quote-form");
                }}
                style={{
                  background: "#FFD05B",
                  borderRadius: "24px",
                  color: "black",
                }}
              >
                JOIN NOW
              </YellowButton>
            </Box>
          </Box>
        </Box>
      </BookWrapper>

      {/* <BookSiteVisit
        onClick={(e) => {
          e.preventDefault();

          navigate("/solar-enquiry-form");
        }}
      /> */}

      {/* <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          mb: 4,
        }}
      >
        <FAQ />
      </Box> */}

      {/* <AnalysisForm /> */}

      <HomeLeadForm
        open={leadFormOpen}
        onClose={() => setLeadFormOpen(false)}
      />
    </Box>
  );
};

export default NewHomePage;
