import { Checkbox, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  setLoginMode,
  setRegisterMode,
  setVerificationMode,
  setVerificationMode2,
} from "../../redux/slices/loginStepSlice";
import { axiAuth, axiosInstance } from "../../utils/axiosInstance";
import SolrufTextField from "../SolrufTextField/SolrufTextField";
import YellowButton from "../YellowButton/YellowButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useState } from "react";
import Loader from "../Loader/Loader";
import { saveUser } from "../../redux/slices/userSlice";
import {
  closeLoginModal,
  removeLoginRedirect,
  setPhone,
} from "../../redux/slices/loginModalSlice";
import { useNavigate } from "react-router";
import {
  Circle,
  FormTitle,
  Nav,
  RoleBox,
  Text,
  UserBox,
  UserNameBox,
  UserTypeBox,
  VendorBox,
} from "./authGuard.style";
import { setProfileData } from "../../redux/slices/ProfileSlice";
import { migrateCart } from "../../redux/slices/cart/cartSlice";
import ConfirmDialog from "../ConfirmDialog/ConfirmDialog";
import { toast } from "react-toastify";
import LoadingButtonPrimary from "../LoadingButtonPrimary/LoadingButtonPrimary";

import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { withStyles } from "@mui/styles";
import { sendWhatsappNotification } from "../../utils/sendWhatsappNotification";
import { syncCart } from "../../redux/slices/newCartSlice/newCartSlice";
import validateGST from "../../utils/validateGst";
import SelectFieldWithRegister from "../Custom/SelectFieldWithRegister/SelectFieldWithRegister";

const StyledCheckbox = withStyles({
  root: {
    "& .MuiSvgIcon-root": {
      borderRadius: "50%",
    },
  },
})((props) => (
  <Checkbox
    disableRipple
    icon={<RadioButtonUncheckedIcon />}
    checkedIcon={<RadioButtonCheckedIcon />}
    {...props}
  />
));

const AuthGuard = () => {
  const { loginMode, registerMode, verificationMode, verificationMode2 } =
    useSelector((state) => state.loginStep); // mode switching state
  const {
    from,
    phone: userEnteredNumber,
    name: userEnteredName,
  } = useSelector((state) => state.loginModal); // modal state
  const { cart } = useSelector((state) => state.cart);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      phone: userEnteredNumber ? userEnteredNumber : "",
      first_name: userEnteredName ? userEnteredName : "",
    },
  });
  console.log(errors);

  const [differentVendorCartAlert, setDifferentVendorCartAlert] = useState({
    role: "Cart From Different Vendor",
    isOpen: false,
    title: "Delete The previous Cart?",
    message: "Current cart will be stored in the new cart",
    cacheRole: "Cart",
  });

  const onConfirmMigrateCart = async () => {
    const { status } = await axiAuth.get("api/carts/clear");
    if (status === 200) {
      axiAuth
        .post("api/carts", {
          carts: cart,
        })
        .then(({ status, data }) => {
          //
        })
        .catch((err) => {
          toast.warn(err.message);
        });
      dispatch(migrateCart(cart));
      dispatch(closeLoginModal());
    }
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [userPhone, setUserPhone] = useState("");
  const [sendingOtp, setSendingOtp] = useState(false);
  const [verifying, setVerifying] = useState(false);
  const [registering, setRegistering] = useState(false);
  const [role, setRole] = useState("Vendor");
  const [otpError, setOtpError] = useState("");
  const [registerError, setRegisterError] = useState("");

  const handleUserClick = (event) => {
    setRole("User");
  };
  const handleVendorClick = (event) => {
    setRole("Vendor");
  };

  const [whatsappChecked, setWhatsappChecked] = useState(false);
  const [gstChecked, setGstChecked] = useState(false);

  const handleWhatsappChecked = (event) => {
    setWhatsappChecked(event.target.checked);
  };

  const handleGstChecked = (event) => {
    setGstChecked(event.target.checked);
  };

  console.log({ whatsappChecked });

  const handleRegister = async (userInputs) => {
    const {
      phone,
      otp,
      first_name,
      last_name,
      email,
      gst,
      pincode,
      address,
      system_size,
      average_monthly_light_bill,
      system_type,
      mounting_type,
    } = userInputs;

    if (loginMode) {
      try {
        setSendingOtp(true);
        const { data } = await axiosInstance.post("api/login", {
          mobile: phone,
        });
        if (data.message === "OTP Sent") {
          setUserPhone(phone);
          dispatch(setLoginMode(false));
          setSendingOtp(false);
          dispatch(setVerificationMode(true));
        }
      } catch (error) {
        setUserPhone(phone);
        dispatch(setLoginMode(false));
        dispatch(setRegisterMode(true));
        setSendingOtp(false);
      }
    }

    if (verificationMode || verificationMode2) {
      try {
        setVerifying(true);
        const { data } = await axiosInstance.post("api/verify-otp", {
          mobile: phone,
          otp,
        });

        if (data.message === "OTP Verified") {
          setOtpError("");
          dispatch(setVerificationMode(false));
          dispatch(setVerificationMode2(false));
          dispatch(setLoginMode(true));
          dispatch(saveUser(data));
          setVerifying(false);
          dispatch(setPhone(""));

          //* setting profile data to state
          let first_name;
          let phone;
          axiAuth
            .get("/api/profile")
            .then(async (res) => {
              let profileData = res.data.data;
              profileData["role"] = data.user.role;
              dispatch(setProfileData(profileData));
              first_name = res.data?.data?.first_name;
              phone = res.data?.data?.mobile;

              axiAuth
                .get("/api/shoppingcarts")
                .then(({ data }) => {
                  console.log(data);

                  dispatch(
                    syncCart(
                      data?.carts?.map((cartItem) => ({
                        ...cartItem?.product_details,
                        quantity: cartItem.quantity,
                      }))
                    )
                  );
                })
                .catch((err) => {});

              dispatch(closeLoginModal());
            })
            .catch((err) => {
              toast.warn("Error setting profile data");
            })
            .finally(() => {
              sendWhatsappNotification(
                first_name,
                phone,
                "login_regard",
                [first_name, "+91-8600694140", "sumit@solruf.com"],
                {
                  url: "https://i.ibb.co/qpPwKgS/login-regard.png",
                  filename: "test",
                }
              );
            });

          if (from) {
            navigate(from);
            dispatch(removeLoginRedirect());
          }
        }
      } catch (error) {
        setOtpError(error.response.data.message);
        setVerifying(false);
      }
    }

    if (registerMode) {
      try {
        setRegistering(true);
        const registerData = {
          first_name,
          last_name,
          mobile: phone,
          role,
          opt_for_whatsapp_messages: whatsappChecked,
          gst,
        };

        if (email) {
          registerData.email = email;
        }

        // Conditionally add optional form fields if they are provided
        if (pincode) {
          registerData.pincode = pincode;
        }
        if (address) {
          registerData.address = address;
        }
        if (system_size) {
          registerData.system_size = system_size;
        }
        if (average_monthly_light_bill) {
          registerData.average_monthly_light_bill = average_monthly_light_bill;
        }
        if (system_type) {
          registerData.system_type = system_type;
        }
        if (mounting_type) {
          registerData.mounting_type = mounting_type;
        }

        const { data } = await axiosInstance.post("api/register", registerData);
        if (data.message === "Success") {
          setRegisterError("");
          setUserPhone(phone);

          sendWhatsappNotification(
            first_name,
            phone,
            "register_regard",
            [
              first_name,
              "One place Solar Marketplace and Installation Platform",
              "+91-8600694140",
              "sumit@solruf.com",
            ],
            {
              url: "https://i.ibb.co/zFYV9yh/register-regard.jpg",
              filename: "test",
            }
          );
          setRegistering(false);
          dispatch(setRegisterMode(false));
          dispatch(setVerificationMode2(true));
        }
      } catch (error) {
        setRegisterError(error.message);
        setRegistering(false);
      }
    }
  };

  const backHandler = () => {
    setOtpError("");
    if (verificationMode) {
      dispatch(setVerificationMode(false));
      dispatch(setLoginMode(true));
    } else if (registerMode) {
      setRegisterError("");
      dispatch(setRegisterMode(false));
      dispatch(setLoginMode(true));
    }
  };

  const backToRegisterHandler = () => {
    setOtpError("");
    dispatch(setVerificationMode2(false));
    dispatch(setRegisterMode(true));
  };

  const resendOtpHandler = async () => {
    try {
      const { data } = await axiosInstance.post("api/login", {
        mobile: userPhone,
      });
      if (data.message === "OTP Sent") {
        toast.success("OTP Resent");
        setValue("otp", "");
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <Box>
      <Box component="form" onSubmit={handleSubmit(handleRegister)}>
        {loginMode && (
          <>
            <FormTitle>Sign Up / Log In</FormTitle>
            <SolrufTextField
              size="small"
              type="number"
              label="Enter Your Number"
              {...register("phone", {
                required: {
                  value: true,
                  message: "Please input a number to continue",
                },
                minLength: {
                  value: 10,
                  message: "Number must be at least 10 characters long",
                },
                maxLength: {
                  value: 10,
                  message: "Number must be at most 10 characters long",
                },
              })}
              error={errors.phone}
              helperText={errors.phone && errors.phone.message}
            />
            <LoadingButtonPrimary
              loading={sendingOtp}
              fullWidth
              sx={{
                marginTop: "1rem",
                fontSize: "1rem",
              }}
              type="submit"
            >
              {sendingOtp ? "Sending OTP" : "Join Solruf"}
            </LoadingButtonPrimary>
          </>
        )}

        {verificationMode && (
          <>
            <FormTitle>Verify OTP</FormTitle>
            <Nav>
              <ArrowBackIcon onClick={backHandler} />
              <Typography>Enter OTP sent to {userPhone}</Typography>
            </Nav>
            <SolrufTextField
              size="small"
              sx={{ appearance: "none" }}
              onChange={() => setOtpError("")}
              type="number"
              label="Enter OTP"
              {...register("otp", {
                required: {
                  value: true,
                  message: "Please input OTP to continue",
                },
                minLength: {
                  value: 4,
                  message: "OTP must be at least 4 characters long",
                },
                maxLength: {
                  value: 4,
                  message: "OTP must be at most 4 characters long",
                },
              })}
              error={errors.otp}
              helperText={errors.otp ? errors.otp.message : ""}
            />
            <Typography
              sx={{ mt: 1.5, textAlign: "center", color: "error.main" }}
            >
              {otpError}
            </Typography>
            <Box sx={{ my: 4 }}>
              <Typography
                variant="body2"
                sx={{ color: "gray", textAlign: "center" }}
              >
                Didn't receive OTP?{" "}
              </Typography>
              <Typography
                component="a"
                sx={{
                  textDecoration: "underline !important",
                  color: "blue",
                  textAlign: "center",
                  display: "block",
                  cursor: "pointer",
                }}
                onClick={resendOtpHandler}
              >
                Resend OTP
              </Typography>
            </Box>
            {verifying ? (
              <Loader />
            ) : (
              <YellowButton
                style={{
                  display: "block",
                  width: "100%",
                  marginTop: "1rem",
                  fontSize: "1.2rem",
                }}
                type="submit"
              >
                Submit
              </YellowButton>
            )}
          </>
        )}

        {verificationMode2 && (
          <>
            <FormTitle>Verify OTP</FormTitle>
            <Nav>
              <ArrowBackIcon onClick={backToRegisterHandler} />
              <Typography>Enter OTP sent to {userPhone}</Typography>
            </Nav>
            <SolrufTextField
              size="small"
              sx={{ appearance: "none" }}
              onChange={() => setOtpError("")}
              type="number"
              label="Enter OTP"
              {...register("otp", {
                required: {
                  value: true,
                  message: "Please input OTP to continue",
                },
                minLength: {
                  value: 4,
                  message: "OTP must be at least 4 characters long",
                },
                maxLength: {
                  value: 4,
                  message: "OTP must be at most 4 characters long",
                },
              })}
              error={errors.otp}
              helperText={errors.otp ? errors.otp.message : ""}
            />
            <Typography
              sx={{ mt: 1.5, textAlign: "center", color: "error.main" }}
            >
              {otpError}
            </Typography>
            <Box sx={{ my: 4 }}>
              <Typography
                variant="body2"
                sx={{ color: "gray", textAlign: "center" }}
              >
                Didn't receive OTP?{" "}
              </Typography>
              <Typography
                component="a"
                sx={{
                  textDecoration: "underline !important",
                  color: "blue",
                  textAlign: "center",
                  display: "block",
                  cursor: "pointer",
                }}
                onClick={resendOtpHandler}
              >
                Resend OTP
              </Typography>
            </Box>
            {verifying ? (
              <Loader />
            ) : (
              <YellowButton
                style={{
                  display: "block",
                  width: "100%",
                  marginTop: "1rem",
                  fontSize: "1.2rem",
                }}
                type="submit"
              >
                Submit
              </YellowButton>
            )}
          </>
        )}

        {registerMode && (
          <>
            <FormTitle>Register</FormTitle>
            <Nav>
              <ArrowBackIcon onClick={backHandler} />
            </Nav>
            <UserNameBox>
              <SolrufTextField
                size="small"
                sx={{ mr: 2 }}
                type="text"
                label="First Name"
                {...register("first_name", {
                  required: {
                    value: true,
                    message: "First name is required",
                  },
                })}
                error={errors.first_name}
                helperText={errors.first_name && errors.first_name.message}
              />
              <SolrufTextField
                size="small"
                type="text"
                label="Last Name"
                {...register("last_name", {
                  required: {
                    value: true,
                    message: "Last name is required",
                  },
                })}
                error={errors.last_name}
                helperText={errors.last_name && errors.last_name.message}
              />
            </UserNameBox>

            <SolrufTextField
              size="small"
              sx={{ mb: 2 }}
              type="email"
              label="Email (Optional)"
              {...register("email", {
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: "Invalid email address",
                },
              })}
              error={errors.email}
              helperText={errors.email && errors.email.message}
            />

            <SolrufTextField
              size="small"
              type="number"
              value={userPhone}
              disabled
              label="Mobile Number"
              {...register("phone", {
                required: {
                  value: true,
                  message: "Please input a number to continue",
                },
              })}
              sx={{ mb: 2 }}
              // error={errors.phone}
              // helperText={errors.phone && errors.phone.message}
            />
            {role === "User" && (
              <>
                <SolrufTextField
                  size="small"
                  type="number"
                  label="Pincode"
                  {...register("pincode", {
                    required: {
                      value: true,
                      message: "Please input a number to continue",
                    },
                  })}
                  sx={{ mb: 2 }}

                  // error={errors.phone}
                  // helperText={errors.phone && errors.phone.message}
                />
                <SolrufTextField
                  size="small"
                  type="text"
                  label="Address"
                  {...register("address", {
                    required: {
                      value: true,
                      message: "Please input an address to continue",
                    },
                  })}
                  sx={{ mb: 2 }}

                  // error={errors.phone}
                  // helperText={errors.phone && errors.phone.message}
                />
                <SolrufTextField
                  size="small"
                  type="number"
                  label="System Size"
                  {...register("system_size", {
                    required: {
                      value: true,
                      message: "Please input system size to continue",
                    },
                  })}
                  sx={{ mb: 2 }}

                  // error={errors.phone}
                  // helperText={errors.phone && errors.phone.message}
                />
                <SolrufTextField
                  size="small"
                  type="number"
                  label="Average Monthly Light Bill (Optional)"
                  {...register("average_monthly_light_bill", {
                    required: {
                      value: false,
                      message: "Please input average monthly light to continue",
                    },
                  })}
                  sx={{ mb: 2 }}

                  // error={errors.phone}
                  // helperText={errors.phone && errors.phone.message}
                />

                <SelectFieldWithRegister
                  label="Select System Type"
                  name="system_type"
                  register={register}
                  errors={errors}
                  options={[
                    { value: "Ongrid DCR", label: "Ongrid DCR" },
                    { value: "Ongrid NON-DCR", label: "Ongrid NON-DCR" },
                    { value: "Offgrid", label: "Offgrid" },
                    { value: "Hybrid", label: "Hybrid" },
                    {
                      value: "Installation + BOS",
                      label: "Installation + BOS",
                    },
                  ]}
                  sx={{ mb: 2 }}
                />
                <SelectFieldWithRegister
                  label="Select Mounting Type"
                  name="mounting_type"
                  register={register}
                  errors={errors}
                  options={[
                    { value: "Rooftop", label: "Rooftop" },
                    { value: "Ground Mounted", label: "Ground Mounted" },
                  ]}
                  sx={{ mb: 2 }}
                />
              </>
            )}
            {role === "Vendor" && (
              <div>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    mb: 2,
                  }}
                >
                  <StyledCheckbox
                    label="Label"
                    defaultChecked
                    checked={gstChecked}
                    onChange={handleGstChecked}
                    sx={{}}
                  />
                  <Typography variant="subtitle1">
                    Do you want GST Invoice?
                  </Typography>
                </Box>
                {gstChecked === true && (
                  <SolrufTextField
                    size="small"
                    type="text"
                    label="GST Number"
                    {...register("gst", {
                      required: {
                        value: gstChecked === true ? true : false,
                        message: "Please input GST number to continue",
                      },
                      validate: validateGST,
                    })}
                    error={errors.gst}
                    helperText={errors.gst && errors.gst.message}
                  />
                )}
              </div>
            )}

            <RoleBox>
              <Typography variant="h5" fontWeight={600}>
                Register as{" "}
              </Typography>

              <UserTypeBox>
                <VendorBox
                  sx={{
                    background: role === "Vendor" ? "#ffd05b" : "#D0D7D9",
                  }}
                  onClick={handleVendorClick}
                >
                  <Circle
                    sx={{
                      background: role === "Vendor" ? "#000000" : "#D0D7D9",
                    }}
                  ></Circle>
                  <Text>
                    <Typography variant="h6" fontWeight={600}>
                      {" "}
                      Solar Installer / Vendor
                    </Typography>
                    <Typography variant="body2">
                      You have a solar product/service company and woulkd like
                      to promote yout business along with procurement.
                    </Typography>
                  </Text>
                </VendorBox>
                <UserBox
                  sx={{
                    background: role === "User" ? "#ffd05b" : "#D0D7D9",
                  }}
                  onClick={handleUserClick}
                >
                  <Circle
                    sx={{
                      background: role === "User" ? "#000000" : "#D0D7D9",
                    }}
                  ></Circle>
                  <Text>
                    <Typography variant="h6" fontWeight={600}>
                      {" "}
                      Purchase consumer
                    </Typography>
                    <Typography variant="body2">
                      You are here to purchase solar products.
                    </Typography>
                  </Text>
                </UserBox>
              </UserTypeBox>
            </RoleBox>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                mb: 2,
              }}
            >
              <StyledCheckbox
                label="Label"
                defaultChecked
                checked={whatsappChecked}
                onChange={handleWhatsappChecked}
                sx={{}}
              />
              <Typography variant="subtitle1">
                Opt in for Solar Products Whatsapp Updates
              </Typography>
            </Box>

            {/*  error text */}
            {registerError && (
              <Typography sx={{ color: "error.main", textAlign: "center" }}>
                The Mobile number or Email has already been taken!
              </Typography>
            )}

            <LoadingButtonPrimary
              loading={registering}
              sx={{ fontSize: "1rem" }}
              type="submit"
              fullWidth
            >
              {registering ? "Registering" : "Register"}
            </LoadingButtonPrimary>
          </>
        )}
      </Box>

      <ConfirmDialog
        confirmDialog={{
          ...differentVendorCartAlert,
          onConfirm: onConfirmMigrateCart,
        }}
        setConfirmDialog={setDifferentVendorCartAlert}
        variant="warning"
      />
    </Box>
  );
};

export default AuthGuard;
