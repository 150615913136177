import {
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import SendIcon from "@mui/icons-material/Send";
import { FormFeaturesWrapper } from "../../pages/home/home.style";
import Features from "../Features/Features";
import {
  LeadFormWrapper,
  RowWrapper,
} from "../HomeLeadForm/HomeLeadForm.style";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import saveFormDataToGoogleSheet from "../../utils/saveFormDataToSheet";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";

export const FormWrapper = styled(Box)(({ theme }) => ({
  width: "60%",
  display: "flex",
  flexDirection: "column",
  rowGap: "1rem",
  padding: "1rem",
  "@media (max-width: 600px)": {
    padding: "0.3rem",
    rowGap: "0.5rem",
    width: "100%",
  },
}));

const schema = yup.object().shape({
  name: yup.string().required("Name is required"),
  phoneNumber: yup
    .string()
    .required("Phone number is required")
    .matches(/^\d{10}$/, "Phone number must be 10 digits"),
  email: yup
    .string()
    .required("Email is required")
    .email("Invalid email format"),
  pinCode: yup
    .string()
    .required("Pin Code is required")
    .matches(/^\d{6}$/, "Pin Code must be 6 digits"),
  city: yup.string().required("City is required"),
  state: yup.string().required("State is required"),
  address: yup.string().required("Address is required"),
  // predicted_system_capacity_kw: yup
  //   .string()
  //   .required("System Size (kW) is required"),
  // unit_cost: yup.string().required("Electricity Tariff is required"),
  // monthly_units_after_solar: yup
  //   .string()
  //   .required("Monthly Units After Solar is required"),
  // project_type: yup.string().required("Project Type is required"),
  // mounting_type: yup.string().required("Mounting Type is required"),
});

function QuoteFormHome() {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [service, setService] = useState("solarProject");
  const [partnerType, setPartnerType] = useState("");
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data) => {
    setLoading(true); // Set loading to true when the form is submitted

    const now = new Date();
    const formattedDateTime = now.toLocaleString("en-IN", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });

    data.dateTime = formattedDateTime;

    if (service === "partner") {
      data.partner_type = partnerType;
      data.service = service;
      console.log(data);
    } else {
      data.service = service;
    }

    try {
      const response = await fetch(
        "https://script.google.com/macros/s/AKfycbzd8LEqCaFqJUlQySmpZf7U6AxXupSuvLhkwm0uHcfuh0QE-MK3j3JYQerGH7O4W0RMKQ/exec",
        {
          redirect: "follow",
          method: "POST",
          headers: {
            "Content-Type": "text/plain;charset=utf-8",
          },
          body: JSON.stringify(data),
        }
      );

      let reqData;
      if (response.ok) {
        reqData = await response.json();
        console.log(reqData);

        if (reqData.status !== "false") {
          reset();
          toast.success("Your Message was sent!");
        } else {
          toast.warn(reqData["data"]);
        }
      } else {
        toast.warn("Something went wrong");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.warn("Something went wrong");
    } finally {
      setLoading(false); // Set loading to false after the request is complete
    }
  };

  return (
    <Container id="quote-form">
      <Box
        sx={{
          mb: 10,
          mt: 5,
        }}
      >
        <LeadFormWrapper>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
              rowGap: "1.5rem",
              "@media (max-width: 600px)": {
                rowGap: "0.4rem",
              },
            }}
          >
            <Typography
              variant="h4"
              sx={{
                "@media (max-width: 600px)": {
                  fontSize: "1.2rem",
                },
                mb: 2,
              }}
              fontWeight={500}
              textAlign="center"
            >
              What are you looking for?
            </Typography>
          </Box>
          <RowWrapper sx={{ paddingBottom: "2rem" }}>
            <form
              onSubmit={handleSubmit(onSubmit)}
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <FormWrapper>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={service}
                  onChange={(e) => {
                    const selectedValue = e.target.value;
                    setService(selectedValue);
                  }}
                >
                  <FormControlLabel
                    value="solarProject"
                    control={<Radio />}
                    label="Book a Solar Project"
                  />
                  <FormControlLabel
                    value="partner"
                    control={<Radio />}
                    label="Become a Partner"
                  />
                </RadioGroup>

                {service === "partner" && (
                  <FormControl fullWidth required>
                    <InputLabel id="select-partner-type">
                      Partner Type
                    </InputLabel>
                    <Select
                      labelId="select-partner-type"
                      id="select-partner-type"
                      value={partnerType}
                      onChange={(e) => setPartnerType(e.target.value)}
                      label="Partner Type"
                    >
                      <MenuItem value="affiliate">Affiliate Partner</MenuItem>
                      <MenuItem value="channel">Channel Partner</MenuItem>
                    </Select>
                  </FormControl>
                )}

                <Controller
                  name="name"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Name"
                      fullWidth
                      required
                      error={!!errors.name}
                      helperText={errors.name?.message}
                      size="small"
                    />
                  )}
                />
                <Controller
                  name="phoneNumber"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Phone Number"
                      fullWidth
                      required
                      error={!!errors.phoneNumber}
                      helperText={errors.phoneNumber?.message}
                      size="small"
                    />
                  )}
                />
                <Controller
                  name="email"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Email"
                      fullWidth
                      required
                      error={!!errors.email}
                      helperText={errors.email?.message}
                      size="small"
                    />
                  )}
                />
                <Controller
                  name="pinCode"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Pin Code"
                      fullWidth
                      required
                      error={!!errors.pinCode}
                      helperText={errors.pinCode?.message}
                      size="small"
                    />
                  )}
                />
                <Controller
                  name="city"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="City"
                      fullWidth
                      required
                      error={!!errors.city}
                      helperText={errors.city?.message}
                      size="small"
                    />
                  )}
                />
                <Controller
                  name="state"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="State"
                      fullWidth
                      required
                      error={!!errors.state}
                      helperText={errors.state?.message}
                      size="small"
                    />
                  )}
                />
                <Controller
                  name="address"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Address"
                      fullWidth
                      required
                      error={!!errors.address}
                      helperText={errors.address?.message}
                      size="small"
                    />
                  )}
                />

                {service === "partner" && (
                  <Controller
                    name="remarks"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Remarks (Optional)"
                        fullWidth
                        multiline
                        rows={4}
                        size="small"
                      />
                    )}
                  />
                )}

                <Button
                  fullWidth
                  variant="contained"
                  type="submit"
                  sx={{
                    py: 1.5,
                    fontWeight: 600,
                    borderRadius: "10px",
                    mt: 2,
                    backgroundColor: "#4d4d4d",
                    color: "#FFD05B",
                    "&:hover": { backgroundColor: "#000000" },
                  }}
                  disabled={loading}
                >
                  Submit The Form
                  {loading ? (
                    <CircularProgress
                      size={24}
                      sx={{ color: "#FFD05B", ml: 1 }}
                    />
                  ) : (
                    <SendIcon sx={{ ml: 1 }} />
                  )}
                </Button>
              </FormWrapper>
            </form>
          </RowWrapper>
        </LeadFormWrapper>
        {/* <FormFeaturesWrapper sx={{ mt: 2 }}>
          <Features />
        </FormFeaturesWrapper> */}
      </Box>
    </Container>
  );
}

export default QuoteFormHome;
