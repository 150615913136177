import React from "react";
import { Box, Typography } from "@mui/material";

const ComingSoonPage = ({ children }) => {
  return (
    <Box
      sx={{
        position: "relative",
        height: "100%",
        width: "100%",
        overflow: "hidden",
      }}
    >
      {/* Shadow Overlay */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.92)", // Semi-transparent black
          zIndex: 10,
          display: "flex",
          justifyContent: "center",
        }}
      >
        {/* Coming Soon Text */}
        <Typography
          variant="h3"
          sx={{
            color: "white",
            fontWeight: "bold",
            textAlign: "center",
            textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
            mt: 40,
          }}
        >
          Page will be live soon!
        </Typography>
      </Box>

      {/* Page Content (optional, visible under the overlay) */}
      <Box
        sx={{
          position: "relative",
          zIndex: 1,
          height: "100%",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default ComingSoonPage;
