import { Box, Container, styled, Typography } from "@mui/material";

import panIndiaImage from "../../assets/pan.svg";
import solarImage from "../../assets/solar-home.svg";
import securityImage from "../../assets/security.svg";
import portfolioImage from "../../assets/portfolio.svg";

export const FormWrapper = styled(Box)(({ theme }) => ({
  width: "60%",
  display: "flex",
  flexDirection: "column",
  rowGap: "1rem",
  padding: "1rem",
  "@media (max-width: 600px)": {
    padding: "0.3rem",
    rowGap: "0.5rem",
    width: "100%",
  },
}));

export const FeaturesWrapper = styled(Box)(({ theme }) => ({
  display: "grid", // Use grid layout
  gridTemplateColumns: "repeat(auto-fit, minmax(180px, max-content))", // Items take up only the space they need
  gap: "4rem",
  justifyContent: "center", // Center the grid within the container
  width: "100%",
  "@media (max-width: 600px)": {
    gap: "1rem",
    gridTemplateColumns: "1fr", // Single column on small screens
  },
}));
export const FeatureWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "180px",
  alignItems: "center",
  gap: "0.5rem",
  "@media (max-width: 1200px)": {
    width: "120px",
    "& img": {
      width: "100px !important",
    },
  },
  "@media (max-width: 600px)": {
    flexDirection: "row",
    width: "100%",
    borderRadius: "15px",
    boxShadow: "0px 1.03194px 4.5182px rgba(0, 0, 0, 0.25)",
  },
}));

function ServiceSectionHomePage() {
  return (
    <Container id="service-home">
      <Box
        sx={{
          mb: 10,
          mt: 5,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            rowGap: "1.5rem",
            "@media (max-width: 600px)": {
              rowGap: "0.4rem",
            },
          }}
        >
          <Typography
            variant="h4"
            sx={{
              "@media (max-width: 600px)": {
                fontSize: "1.2rem",
              },
              mb: 2,
            }}
            fontWeight={500}
            textAlign="center"
          >
            Services we provide
          </Typography>

          <FeaturesWrapper>
            <FeatureWrapper>
              <img src={panIndiaImage} alt="" style={{ width: "150px" }} />
              <Typography
                variant="h6"
                sx={{
                  textAlign: "center",
                  "@media (max-width: 600px)": {
                    fontSize: "1.1rem",
                  },
                }}
                component="h3"
              >
                Pan India installation support
              </Typography>
            </FeatureWrapper>
            <FeatureWrapper>
              <img src={solarImage} alt="" style={{ width: "150px" }} />
              <Typography
                variant="h6"
                sx={{
                  textAlign: "center",
                  "@media (max-width: 600px)": {
                    fontSize: "1.1rem",
                  },
                }}
              >
                Solar kits procurement
              </Typography>
            </FeatureWrapper>
            <FeatureWrapper>
              <img src={securityImage} alt="" style={{ width: "150px" }} />
              <Typography
                variant="h6"
                sx={{
                  textAlign: "center",
                  "@media (max-width: 600px)": {
                    fontSize: "1.1rem",
                  },
                }}
              >
                Subsidy and project licensing
              </Typography>
            </FeatureWrapper>
            <FeatureWrapper>
              <img src={portfolioImage} alt="" style={{ width: "150px" }} />
              <Typography
                variant="h6"
                sx={{
                  textAlign: "center",
                  "@media (max-width: 600px)": {
                    fontSize: "1.1rem",
                  },
                }}
              >
                Project management platform tools
              </Typography>
            </FeatureWrapper>
          </FeaturesWrapper>
        </Box>
      </Box>
    </Container>
  );
}

export default ServiceSectionHomePage;
