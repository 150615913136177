import React, { useEffect, useRef, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  MenuItem,
  Pagination,
  Radio,
  Stack,
  styled,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
// import Solruf from "../../SolrufTextField/SolrufTextField";
import SolrufTextField from "../../components/SolrufTextField/SolrufTextField";

import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import LightTextField from "../../components/Custom/LightTextField/LightTextField";
import TotalAccordion from "../../components/CustomerDetailsDrawer/TotalAccordion";
import DeleteIcon from "@mui/icons-material/Delete";
import { useReactToPrint } from "react-to-print";
import PrimaryButton from "../../components/Custom/PrimaryButton/PrimaryButton";
import { axiAuth } from "../../utils/axiosInstance";

import { Cards, DashboardCard } from "../MyDashboard/myDashboard.style";
// import Loader from "../Loader/Loader";
import Loader from "../../components/Loader/Loader";
import ProductDetailList from "../../components/ProductDetailList/ProductDetailList";
import SearchBox from "../../components/SearchBox/SearchBox";
import useCategories from "../../hooks/useCategories";
import {
  ProductWrapper,
  SearchDataWrapper,
} from "../../components/PopupSearchField/PopupSearchField.style";
import { useTheme } from "@emotion/react";
import RichEditor from "../../components/richEditor/RichEditor";
import { useSelector } from "react-redux";
import { InfoBar } from "../../components/ServiceComponents/QuotationService";
import QuoteAccordion from "../../components/CustomerDetailsDrawer/QuoteAccordion";
import BackToButton from "../../components/BackToButton/BackToButton";

export const Table = styled("table")(({ theme }) => ({
  borderCollapse: "collapse",
  border: "1px solid #e0e0e0",
  width: "100%",
  "& thead": {
    backgroundColor: "#f5f5f5",
    "& tr": {
      height: 56,
    },
  },
  "& tbody": {
    "& tr": {
      textAlign: "center",
      height: 48,

      "&:nth-of-type(odd)": {
        backgroundColor: "#ffffff",
      },
      "&:nth-of-type(even)": {
        backgroundColor: "#f5f5f5",
      },
      "& td": {
        padding: "0.5rem",
        border: "1px solid #e0e0e0",
      },
    },
  },
}));

export const ProductListWrapper = styled(Box)(({ theme }) => ({}));

export const Flex = styled(Box)(({ theme }) => ({
  display: "flex",
}));

export const SolrufTextFieldGray = styled(SolrufTextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    // background: '#f3f3f3',
    "& fieldset": {
      borderColor: theme.palette.primary.main,
      borderWidth: "3px",
    },
    "&:hover fieldset": {
      borderWidth: "3px",
      borderColor: theme.palette.primary.main,
    },
    "&.Mui-focused fieldset": {
      borderWidth: "3px",
      borderColor: theme.palette.primary.main,
    },
  },
}));

export const VendorAdd = () => {
  // start product search
  const isFirstRenderSearchTerm = useRef(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [vendorSearchTerm, setVendorSearchTerm] = useState("");
  const searchTermChangeHandler = async (e) => {
    const term = e.target.value;
    setSearchTerm(term);

    console.log(term);

    // await searchHandler(e);
  };

  const vendorSearchTermChangeHandler = async (e) => {
    const term = e.target.value;
    setVendorSearchTerm(term);

    console.log(term);

    // await searchHandler(e);
  };

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    // Don't run on the initial render
    if (isFirstRenderSearchTerm.current) {
      isFirstRenderSearchTerm.current = false; // Set it to false after the first render
      return;
    }

    // console.log(selectedCategory, selectedSubCategory);
    searchHandler(searchTerm);
  }, [searchTerm]);

  const { categories } = useCategories("product", null);
  const [subCategories, setSubCategories] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");

  useEffect(() => {
    if (selectedCategory) {
      axiAuth
        .get(`api/categories?parent=${selectedCategory}`)
        .then((res) => {
          if (res.status === 200) {
            setSubCategories(res.data?.categories);
          }
        })
        .catch((err) => {});
    }
  }, [selectedCategory]);

  const [products, setProducts] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [productsLoading, setProductLoading] = useState(true);
  const [vendorsLoading, setVendorsLoading] = useState(true);
  const [productsError, setProductsError] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [selectedProductLoading, setSelectedProductLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [items, setItems] = useState([]);
  const [formMode, setFormMode] = useState(false);
  const [updateMode, setUpdateMode] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    mobile: "",
    address: "",
    remarks: "",
  });

  useEffect(() => {
    if (selectedVendor) {
      setFormData({
        name: selectedVendor.name || "",
        mobile: selectedVendor.mobile || "",
        address: selectedVendor.address || "",
        remarks: selectedVendor.remarks || "",
      });
    }
  }, [selectedVendor]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    setProductLoading(true);
    setProductsError(false);
    axiAuth
      .get(`api/search/all-products?page=${page}`)
      .then((res) => {
        setProducts(res.data);
        setProductLoading(false);
        setProductsError(false);
      })
      .catch((err) => {
        setProductLoading(false);
        setProductsError("Error Fetching Products");
      });
  }, [page]);

  const searchHandler = async (e) => {
    // e.preventDefault();
    await searchProducts();
  };

  const searchOnSubCategorySet = (e) => {
    e.preventDefault();
    const subCategory = e.target.value;
    console.log(subCategory);
    setSelectedSubCategory(subCategory);
  };

  const searchProducts = async () => {
    try {
      // if (!selectedCategory || !selectedSubCategory) {
      //   toast.warn("Please select category and sub category");
      //   return;
      // }
      setProductLoading(true);

      const { data } = await axiAuth.get(
        `api/search/all-products?page=${page}&sub_category_id=${selectedSubCategory}&search=${searchTerm}`
      );

      setProducts(data);
      setProductLoading(false);
    } catch (error) {
      setProductLoading(false);
      setProductsError("Error Fetching Products");
    }
  };

  const clickHandler = async (product) => {
    console.log(product);
    const existingProduct = items.find(
      (item) => item.id === product.product_id
    );

    if (existingProduct) {
      toast.warn("Product already exists!", {
        position: toast.POSITION.TOP_CENTER,
      });
      return; // Don't add the duplicate product
    }

    setItems((prevItems) => [
      ...prevItems,
      {
        id: product.product_id,
        product_name: product.product_name,
        price: "0",
      },
    ]);
  };

  const handleSubmit = async () => {
    try {
      const response = await axiAuth.post("/api/admin/custom-vendor", {
        ...formData,
        products: items,
      });
      toast.success("Vendor Saved Successfully");
      // Show success toast here
    } catch (error) {
      // console.error("Error saving data:", error);
      toast.error("Something went wrong");
    }
  };

  const handleUpdateSubmit = async () => {
    try {
      const response = await axiAuth.put(
        `/api/admin/custom-vendor/${selectedVendor?.id}`,
        {
          ...formData,
          products: items,
        }
      );
      toast.success("Vendor Saved Successfully");
      // Show success toast here
    } catch (error) {
      // console.error("Error saving data:", error);
      toast.error("Something went wrong");
    }
  };

  useEffect(() => {
    // setProductsError(false);
    if (vendorsLoading) {
      axiAuth
        .get(`api/admin/custom-vendors?page=${page}`)
        .then((res) => {
          setVendors(res.data);
          setVendorsLoading(false);
        })
        .catch((err) => {
          setVendorsLoading(false);
        });
    }
  }, [page, vendorsLoading]);

  const vendorSearchHandler = async (e) => {
    // e.preventDefault();
    await searchVendors();
  };

  const searchVendors = async () => {
    try {
      const { data } = await axiAuth.get(
        `api/admin/search-custom-vendors?page=${page}&search=${vendorSearchTerm}`
      );

      setVendors(data);
      setVendorsLoading(false);
    } catch (error) {
      setVendorsLoading(false);
    }
  };

  return (
    <Box
      sx={{
        boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
        height: "100%",
        backgroundColor: "#ffffff",
        borderRadius: "10px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        mt: 5,
        mb: 5,
        px: isSmallScreen ? 1 : 16,
        py: isSmallScreen ? 1 : 4,
        mx: 4,
        // "@media (max-width: 1600px)": { flexDirection: "column-reverse" },
        "@media (max-width: 1920px)": { flexDirection: "column" },
      }}
      component="form"
    >
      {/* List vendors */}
      {!formMode && (
        <>
          <PrimaryButton
            sx={{ width: "100%" }}
            onClick={() => setFormMode(true)}
          >
            Create Vendor
          </PrimaryButton>
          <ProductListWrapper style={{ marginTop: "20px" }}>
            <Container style={{ padding: 0 }}>
              <Box sx={{ my: 2 }}>
                <SearchBox
                  onChange={vendorSearchTermChangeHandler}
                  placeholder="Search Vendors"
                  searchTerm={vendorSearchTerm}
                  onSearch={vendorSearchHandler}
                />
              </Box>

              <InfoBar>
                <Flex sx={{ alignItems: "center" }}>
                  <Typography>
                    <strong>Total Vendors: {vendors?.current_count}</strong>
                  </Typography>
                </Flex>
              </InfoBar>
              <List
                sx={{
                  width: "100%",
                  bgcolor: "background.paper",
                  maxHeight: "250px !important",
                  overflowY: "auto",
                }}
              >
                {vendorsLoading ? (
                  <Loader />
                ) : (
                  vendors?.vendors?.data?.map((product, index) => (
                    <>
                      <ListItem
                        key={product.id}
                        sx={{
                          cursor: "pointer",
                          "&:hover": { bgcolor: " rgb(255 253 247)" },
                          alignItems: { xs: "center", sm: "flex-start" },
                          justifyContent: "space-between",
                          borderRadius: "8px",
                          //   bgcolor:
                          //     selectedProduct?.product_id === product?.product_id
                          //       ? "secondary.lightYellow"
                          //       : "",
                          padding: { xs: "8px 12px", sm: "12px 16px" },
                        }}
                        onClick={async () => {
                          console.log("vendor", product);
                          // const { data } = await axiAuth.get(
                          //   `api/admin/custom-vendors/${product?.id}`
                          // );
                          setSelectedVendor(product);

                          let parsedproducts = [];
                          if (typeof product?.products === "string") {
                            try {
                              parsedproducts = JSON.parse(product?.products);
                              setItems(parsedproducts);
                            } catch (error) {
                              console.error(
                                "Error parsing products JSON:",
                                error
                              );
                            }
                          } else {
                            parsedproducts = product?.products || items;
                            setItems(parsedproducts);
                          }

                          setUpdateMode(true);

                          setFormMode(true);
                        }}
                      >
                        {/* <ListItemAvatar
                        sx={{ display: { xs: "none", sm: "block" } }}
                      >
                        <Avatar
                          alt="Remy Sharp"
                          src={product.product_thumbnail}
                          sx={{
                            borderRadius: "5px",
                            width: "3rem",
                            height: "3rem",
                            mr: 2,
                          }}
                        />
                      </ListItemAvatar> */}
                        {/* <Typography
                        variant="span"
                        style={{ fornSize: "18px", fontWeight: "bold" }}
                      >
                        {index + 1}
                      </Typography> */}
                        <ListItemText
                          primary={
                            <>
                              <Typography
                                sx={{
                                  color: {
                                    // xs: "#2448FC",
                                    sm: "inherit",
                                    xs: "inherit",
                                  },
                                }}
                              >
                                {index + 1}
                                {". "}
                                {product.name + " | " + product.mobile}
                              </Typography>
                            </>
                          }
                          secondary={
                            <>
                              {/* <Typography
                              sx={{ display: { xs: "none", sm: "inline" } }}
                              component="span"
                              variant="body2"
                              color="text.primary"
                            >
                              Solruf Product
                            </Typography> */}
                            </>
                          }
                        />
                        <ListItemButton
                          sx={{
                            display: {
                              // xs: "block",
                              sm: "none",
                              xs: "none",
                              textAlign: "end",
                            },
                          }}
                        >
                          <Radio
                            style={{ "&$checked": { color: "#4B8DF8" } }}
                            sx={{ color: "#2448FC" }}
                          />
                        </ListItemButton>
                      </ListItem>
                      <Divider
                        component="li"
                        sx={{
                          borderBottom: {
                            // xs: "2px solid black",
                            sm: "inherit",
                            xs: "inherit",
                          },
                          width: "100%",
                        }}
                      />
                    </>
                  ))
                )}
              </List>

              {/* ============ pagination section ============ */}
              <Pagination
                count={products.last_page}
                page={page}
                onChange={(e, page) => setPage(page)}
                color="primary"
                shape="rounded"
                sx={{ "& ul": { justifyContent: "center" }, mt: 2 }}
              />
            </Container>
          </ProductListWrapper>
        </>
      )}

      {/* End List vendors */}
      {formMode && (
        <>
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  mb: 5,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <BackToButton
                  onClick={() => {
                    setItems([]);
                    setUpdateMode(false);
                    setFormMode(false);
                    setSelectedVendor(null);
                    setVendorsLoading(true);
                  }}
                />
                <Typography variant="h5">
                  {!updateMode && "CREATE VENDOR"}
                  {updateMode && "UPDATE VENDOR"}
                </Typography>
              </Box>
              {updateMode ? (
                <PrimaryButton onClick={handleUpdateSubmit}>
                  Update vendor
                </PrimaryButton>
              ) : (
                <PrimaryButton onClick={handleSubmit}>
                  Save vendor
                </PrimaryButton>
              )}
            </Box>

            <Box sx={{ mb: 6 }}>
              {selectedVendor && (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: 2,
                    }}
                  >
                    <SolrufTextField
                      size="small"
                      type="text"
                      label="Vendor Name"
                      name="name"
                      defaultValue={selectedVendor.name}
                      onChange={handleInputChange}
                    />
                    <SolrufTextField
                      size="small"
                      type="text"
                      label="Mobile"
                      name="mobile"
                      defaultValue={selectedVendor.mobile}
                      onChange={handleInputChange}
                    />
                  </Box>

                  <Box sx={{ mt: 2 }}>
                    <SolrufTextField
                      size="small"
                      type="text"
                      label="Address"
                      name="address"
                      onChange={handleInputChange}
                      defaultValue={selectedVendor.address}
                    />
                  </Box>
                  <Box sx={{ mt: 2 }}>
                    <SolrufTextField
                      size="small"
                      type="text"
                      label="Remarks"
                      name="remarks"
                      onChange={handleInputChange}
                      defaultValue={selectedVendor.remarks}
                    />
                  </Box>
                </>
              )}
              {!selectedVendor && (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: 2,
                    }}
                  >
                    <SolrufTextField
                      size="small"
                      type="text"
                      label="Vendor Name"
                      name="name"
                      onChange={handleInputChange}
                    />
                    <SolrufTextField
                      size="small"
                      type="text"
                      label="Mobile"
                      name="mobile"
                      onChange={handleInputChange}
                    />
                  </Box>

                  <Box sx={{ mt: 2 }}>
                    <SolrufTextField
                      size="small"
                      type="text"
                      label="Address"
                      name="address"
                      onChange={handleInputChange}
                    />
                  </Box>
                  <Box sx={{ mt: 2 }}>
                    <SolrufTextField
                      size="small"
                      type="text"
                      label="Remarks"
                      name="remarks"
                      onChange={handleInputChange}
                    />
                  </Box>
                </>
              )}
            </Box>
          </Box>
          <QuoteAccordion
            title="Add Products"
            titleStyle={{ fontSize: "1rem" }}
            defaultExpanded
          >
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ width: "49%" }}>
                <ProductListWrapper>
                  <Container maxWidth="xl">
                    {/* ============ filter section ============ */}
                    <Flex sx={{ my: 2 }}>
                      <SolrufTextFieldGray
                        select
                        size="small"
                        label="Select Category"
                        value={selectedCategory}
                        onChange={(e) => setSelectedCategory(e.target.value)}
                        sx={{ mr: 2 }}
                      >
                        {categories.map(({ category_id, name }) => (
                          <MenuItem key={category_id} value={category_id}>
                            {name}
                          </MenuItem>
                        ))}
                      </SolrufTextFieldGray>

                      <SolrufTextFieldGray
                        select
                        size="small"
                        label="Select Category"
                        defaultValue={selectedSubCategory}
                        onChange={searchOnSubCategorySet}
                      >
                        {subCategories.map(({ category_id, name }) => (
                          <MenuItem key={category_id} value={category_id}>
                            {name}
                          </MenuItem>
                        ))}
                      </SolrufTextFieldGray>
                    </Flex>

                    <Box sx={{ my: 2 }}>
                      <SearchBox
                        onChange={searchTermChangeHandler}
                        placeholder="Search Product"
                        searchTerm={searchTerm}
                        onSearch={searchHandler}
                      />
                    </Box>

                    <InfoBar>
                      <Flex sx={{ alignItems: "center" }}>
                        <Typography>
                          <strong>
                            Total Products: {products?.current_count}
                          </strong>
                        </Typography>
                      </Flex>
                    </InfoBar>

                    {/*  product list */}
                    <SearchDataWrapper sx={{ rowGap: "1.5rem" }}>
                      {productsLoading ? (
                        <Loader />
                      ) : (
                        products?.products?.map((item, index) => (
                          <ProductWrapper onClick={() => clickHandler(item)}>
                            <Box
                              sx={{
                                width: "100%",
                                height: "90px",
                                overflow: "hidden",
                                mb: "0.5rem",
                              }}
                            >
                              <img
                                src={item?.product_thumbnail}
                                alt="default"
                                style={{
                                  width: "100%",
                                  // make the image cover the whole box
                                  height: "100%",
                                  objectFit: "cover",
                                }}
                              />
                            </Box>
                            <Typography
                              sx={{
                                textAlign: "center",
                                fontSize: "0.7rem",
                                fontWeight: "500",
                                color: "#000000",
                                px: 1,
                                pb: 1,
                              }}
                            >
                              {item?.product_name.slice(0, 25)}
                            </Typography>
                          </ProductWrapper>
                        ))
                      )}
                    </SearchDataWrapper>
                    <Pagination
                      count={products.last_page}
                      page={page}
                      onChange={(e, page) => setPage(page)}
                      color="primary"
                      shape="rounded"
                      sx={{ "& ul": { justifyContent: "center" }, mt: 2 }}
                    />
                  </Container>
                </ProductListWrapper>
              </Box>

              <Box sx={{ width: "49%" }}>
                {items.length == 0 && (
                  <h2 style={{ textAlign: "center", marginTop: "20px" }}>
                    No product added
                  </h2>
                )}
                {items.map((item, index) => (
                  <Box
                    key={item.id}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      mb: 2,
                      mt: 4,
                    }}
                  >
                    <SolrufTextField
                      size="small"
                      type="text"
                      label="Product Name"
                      defaultValue={item.product_name}
                      // onChange={(e) => {
                      //   const newItems = [...items];
                      //   newItems[index].product_name = e.target.value;
                      //   setItems(newItems);
                      // }}
                    />
                    <SolrufTextField
                      style={{ width: "30%" }}
                      size="small"
                      type="number"
                      label="Price"
                      defaultValue={item.price}
                      onChange={(e) => {
                        const newItems = [...items];
                        newItems[index].price = e.target.value;
                        setItems(newItems);
                      }}
                      sx={{ mx: 2 }}
                    />
                    <PrimaryButton
                      sx={{ width: "50%" }}
                      onClick={() => {
                        const newItems = items.filter((_, i) => i !== index);
                        setItems(newItems);
                      }}
                    >
                      Remove
                    </PrimaryButton>
                  </Box>
                ))}
              </Box>
            </Box>
          </QuoteAccordion>
        </>
      )}
    </Box>
  );
};
