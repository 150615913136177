import axios from "axios";
import { createFormDataFromObject } from "./utils";

async function saveFormDataToGoogleSheet(dataObject, sheetLink) {
  console.log(dataObject);
  const formData = createFormDataFromObject(dataObject);

  return await axios.post(sheetLink, formData);
}

export default saveFormDataToGoogleSheet;
